


























































































































































































































































































import { Store, TForm } from '@/store/store';
import Component from 'vue-class-component';
import NumTrack from '@/components/misc/NumberTracker.vue'
import GenericForm from '@/components/forms/GenericForm.vue'
import PasswordValidator from '@/components/forms/PasswordValidator.vue'
import CardPayment from '@/components/forms/CardPayment.vue'
import {Login as LoginRoute} from '@/router'
import { Watch } from 'vue-property-decorator';

@Component({
  components:{
    NumTrack,
    GenericForm,
    PasswordValidator,
    CardPayment
  }
})
export default class DistributorRegistration extends Store {
  componentTitle = "Distributor Registration"
  codeIsValid = false;
  currStep = 0;
  steps = [
     {
        number: 0,
        title: 'Business Profiles',
        completed: false
      },
      {
        number: 1,
        title: 'Billing Details',
        completed: false
      },
      {
        number: 2,
        title: 'Login Credentials',
        completed: false
      },
  ]
  profile : Parameters<typeof Store.prototype.distributorRegistrationStepOne>[0]['profile'] = {
    business_name:{
      value: '',
      errors: []
    },
    lead_contact:{
      value: '',
      errors: []
    },
    country:{
      value: '',
      errors: []
    },
    postcode:{
      value: '',
      errors: []
    },
    county_state:{
      value: '',
      errors: []
    },
    address_line_1:{
      value: '',
      errors: []
    },
    email:{
      value: '',
      errors: []
    },
    town:{
      value: '',
      errors: []
    },
    web_address:{
      value: '',
      errors: []
    },
    address_line_2:{
      value: '',
      errors: []
    },
  }
  billing : Parameters<typeof Store.prototype.distributorRegistrationStepTwo>[0]['details'] = {
    full_name:{
      value: '',
      errors: []
    },
    email:{
      value: '',
      errors: []
    },
    phone:{
      value: '',
      errors: []
    },
    country_id:{
      value: 0,
      errors: []
    },
    address_line_1:{
      value: '',
      errors: []
    },
    county_state:{
      value: '',
      errors: []
    },
    postcode:{
      value: '',
      errors: []
    },
    additional_email:{
      value: '',
      errors: []
    },
    vat_number:{
      value: '',
      errors: []
    },
    address_line_2:{
      value: '',
      errors: []
    },
    town:{
      value: '',
      errors: []
    },
  }
  creds: Parameters<typeof Store.prototype.distributorRegistrationStepThree>[0]['creds'] = {
    password: {
      value: '',
      errors: []
    },
    password_confirmation: {
      value: '',
      errors: []
    },
    email: {
      value: '',
      errors: []
    },

  }
  async mounted(){
    // check token 
    await this.distributorRegistrationValidateToken(this.$route.params.code).then(async (d) => {
      // this.currStep = +d.current_step
      Object.keys(d.business_profile).map((e) => {
        try { 
          //@ts-ignore
          this.profile[e].value = d.business_profile[e]
        }
        catch (e) {

        }
      })
      Object.keys(d.billing_details).map((e) => {
        try { 
          //@ts-ignore
          this.billing[e].value = d.billing_details[e]
        }
        catch (e) {

        }
      })
      this.codeIsValid = true;
      
      if(!d.has_manual_invoices){
        this.steps.push({
          number: 3,
          title: 'Payment Method',
          completed: false
        })
      }
    }).catch((err) => {
      this.$toast.error('Invalid Token')
      this.$router.push(LoginRoute)
    })
  }

  async saveProfile(){
    this.setSending(true)
    this.clearFormErrors(this.profile)
    await this.distributorRegistrationStepOne({
      profile: {...this.profile},
      code: this.$route.params.code
    }).then(() => {
      this.setSending(false)
      this.nextStep()
     }).catch(({errors}) => {
      this.setSending(false)
      Object.keys(errors).map((i:string)=>{
        //@ts-ignore
        this.profile[i].errors = errors[i]
      })
    })
  }

  async saveBilling(){
    this.setSending(true)
    this.clearFormErrors(this.billing)
    await this.distributorRegistrationStepTwo({
      details: {...this.billing},
      code: this.$route.params.code
    }).then(() => {
      this.setSending(false)
      this.nextStep()
     }).catch(({errors}) => {
      this.setSending(false)
      Object.keys(errors).map((i:string)=>{
        //@ts-ignore
        this.billing[i].errors = errors[i]
      })
    })
  }

  async saveCredentials(){
    this.setSending(true)
    this.clearFormErrors(this.creds)
    await this.distributorRegistrationStepThree({
      creds: {...this.creds},
      code: this.$route.params.code
    }).then(() => {
      this.setSending(false)
      this.nextStep()
    }).catch(({errors}) => {
      this.setSending(false)
      Object.keys(errors).map((i:string)=>{
        //@ts-ignore
        this.creds[i].errors = errors[i]
      })
    })
  }

  async savePayment(
    data:{
      el: any,
      secret: string
    }
  )
  {
    this.setSending(true)
    this.distributorRegistrationStepFour({
      code: this.$route.params.code,
      card: data.el,
      stripe_secret: data.secret
    }).then(() => {
      this.setSending(false)
      this.nextStep()
    }).catch((err) => {
      this.setSending(false)
      this.$toast.error(err)
    })
  }

  endFlow(error?: string){
    error ? this.$toast.error(error) : this.$toast.success('All Systems Are A Go')
    this.$router.push(LoginRoute)
  }

  @Watch('completed')
  finishApplication(){
    if(this.completed){
      this.$toast.success('You Are Now Registered')
      this.$router.push(LoginRoute)
    }
  }

  get completed(){
    return this.currStep > (this.steps.length - 1)
  }

  nextStep(){
    ++this.currStep
  }

  prevStep(){
    --this.currStep
  }

  transistionToStep(num: number){
    
  }

  clearFormErrors(obj: TForm<any>){
    for(var i in obj){
      obj[i].errors = []
    }
  }
}
