


















import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Emit, Watch } from 'vue-property-decorator'

interface StepsI {
  number: number,
  title: string
}

@Component
export default class NumberTracker extends Vue {
  @Prop() readonly steps!: StepsI[]
  @Prop() readonly currentStep!: number
  @Prop({default: true}) readonly isClickable!: boolean

  @Emit()
  selected(currentStep:number){
    return currentStep
  }
  
}
