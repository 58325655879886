


































import GenericForm from '@/components/forms/GenericForm.vue'
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Store, TAwait } from '@/store/store';
import { StripeCardElement } from '@stripe/stripe-js';

@Component({
  components:{
    GenericForm,
  }
})
export default class CardPayment extends Store {

  @Prop({default: null}) readonly code!: string
  @Prop(String) readonly fetch!: string
  stripeCardEl?: StripeCardElement
  stripeSec?: any
  cardDeets: TAwait<ReturnType<typeof Store.prototype.getPaymentMethod>> | null = null
  hasManualInvoices: boolean = false
  async mounted(){
    this.refresh()
  }

  @Watch('fetch')
  async refresh(){
    
    
    if(!this.code){
      const pay = await this.getPaymentMethod()
      //@ts-ignore
      this.hasManualInvoices = pay.has_manual_invoices
      if(!pay?.payment_method){
        await this.armStripeEditor()
      }
      else { 
        this.cardDeets = pay
      }
    }
    else{ 
      this.armStripeEditor()
    }
  }

  expiry() {
    if(this.cardDeets) {
      return this.cardDeets.payment_method.exp_month +'/' +this.cardDeets.payment_method.exp_year
    }
  }

  async armStripeEditor(){
    if(this.code){
      const d = await this.distributorRegistrationSetupPayment({
        code: this.code,
        el: '#card'
      })

      this.stripeCardEl = d.card
      this.stripeSec = d.tokens
    }
    else {
      const d = await this.initStripe('#card')
      this.stripeCardEl = d.card
      this.stripeSec = d.tokens
    }

  }

  async deleteActiveCard(){
    await this.removePaymentMethod()
    this.cardDeets = null
    await this.refresh()
  }

  upatePaymentMethod(){
    this.cardDeets =  null
    this.armStripeEditor()
  }

  @Emit()
  submit(){
    return {
      secret: this.stripeSec.stripe_secret,
      el: this.stripeCardEl
    }
  }
}
